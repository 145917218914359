<template>
    <div class="personCard">
        <div v-show="!loading && showUser">
            <div v-if="false">
                <div class="personCard-nav">
                    <van-button @click="showOverlay = true" size="mini" plain icon="plus" type="primary">添加标签</van-button>
                    <van-tag class="personCard-nav-tag" v-for="(item,index) in tagList" :key="index" v-if="show" size="large" closeable color="#f4f4f4" text-color="#555" @close="onClose">标签{{index}}</van-tag>
                </div>
            </div>
            <div class="personCard-bg"></div>
            <div class="personCard-title">
                <h1>专属名片: </h1>
                <div class="personCard-title-Refresh">
                    <van-icon name="replay" />
                    <span>更新头像</span>
                </div>
            </div>
            <div>
                <div ref="printMe" class="personCard-printMe">
                    <div class="personCard-printMe-user">
                        <img :src="`data:image/png;base64,${base64_avatar}`">
                        <div>
                            <h1>{{user_info.staff_name}}</h1>
                            <div>{{user_info.corp_name}}</div>
                        </div>
                    </div>
                    <img class="personCard-printMe-img" :src="`data:image/png;base64,${base64_qrcode}`">
                    <div >扫一扫上面的二维码图案, 加我企业微信</div>
                </div>
            </div>
            <div class="personCard-btn">
                <van-row gutter="20">
                    <van-col span="12">
                        <van-button @click="print" type="primary" block>发送专属名片</van-button>
                    </van-col>
                    <van-col span="12">
                        <van-button @click="showInvited" type="primary" block>邀请数据</van-button>
                    </van-col>
                </van-row>
            </div>
            <van-empty v-if="!showUser">
                <div slot="description">
                    <div style="text-align:center">当前功能不支持群聊使用</div>
                    <div style="text-align:center">请选择单个用户使用</div>
                </div>
            </van-empty>
            <van-overlay :show="showOverlay" @click="showOverlay = false">
                <div class="personCard-wrapper" @click.stop>
                    <div class="personCard-block">
                        <h1>选择标签</h1>
                    </div>
                </div>
            </van-overlay>
            <van-action-sheet v-model="showInvite" title="他邀请来的客户">
                <div class="personCard-content">
                    <van-row style="text-align:center">
                        <van-col span="8">
                            <h1>{{total.total_invited}}</h1>
                            <div>全部客户</div>
                        </van-col>
                        <van-col span="8" class="van-hairline--left van-hairline--right">
                            <h1>{{total.total_lost}}</h1>
                            <div>总流失客户</div>
                        </van-col>
                        <van-col span="8">
                            <h1>{{total.total_amount}}</h1>
                            <div>总净增客户</div>
                        </van-col>
                    </van-row>
                    <div class="personCard-content-btn">
                        <van-button @click="toSearch(1)" size="small" :type="btnNum==1?'primary':'default'">全部</van-button>
                        <van-button @click="toSearch(2)" size="small" :type="btnNum==2?'primary':'default'">流失</van-button>
                        <van-button @click="toSearch(3)" size="small" :type="btnNum==3?'primary':'default'">净增客户</van-button>
                    </div>
                    <div class="personCard-content-num">
                        <van-icon name="apps-o" />
                        <span>共{{list.length}}人</span>
                    </div>
                    <div class="personCard-content-list">
                        <van-list
                            v-model="searchLoading"
                            :finished="finished"
                            :finished-text="listFinishedText"
                            @load="onLoad"
                            >
                            <div v-for="item in list" :key="item.id" class="personCard-content-item">
                                <img :src="item.avatar">
                                <div class="personCard-content-info">
                                    <div>
                                        <span>{{item.name}}</span>
                                    </div>
                                    <div>添加时间: {{item.join_time}}</div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>
            </van-action-sheet>
        </div>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import upload from '@/utils/upload'
    import { wxWorkSend } from '@/common/wx'
    import Loadings from '@/components/loading'
    import { Empty, Tag, Overlay, Col, Row, Toast, ActionSheet} from 'vant'
    export default {
        name:"personCard",
        data() {
            return {
                show: true,
                loading: false,
                finished: false,
                searchLoading: false,
                showOverlay: false,
                output:'',
                base64_avatar:'',
                base64_qrcode:'',
                user_info:{},
                tagList:[],
                showUser:false,
                showInvite:false,
                external_userid:'',
                btnNum:1,
                state:'',
                total:{},
                listFinishedText: '没有更多了',
                list:[],
                pageSize: 20,
                pageCurrent: 1,
            }
        },
        computed: {
            ...mapGetters([
                'qywx_profile'
            ])
        },
        components: {
            [Tag.name]:Tag,
            [Col.name]:Col,
            [Row.name]:Row,
            [Empty.name]:Empty,
            [Overlay.name]:Overlay,
            [ActionSheet.name]:ActionSheet,
            loadings:Loadings,
        },
        created () {
            // this.getTag()
            // this.getBase64()
            // this.getMsg()
            this.loading = true
        },
        mounted(){
            if(this.qywx_profile == 1){
                this.getCurExternalChat()
            }
        },
        methods: {
            getTag() {
                this.$api.qywx_tag_index()
                .then(res=>{

                })
            },
            getBase64(){
                // this.$api.qywx_external_contact_contact_base64({external_userid:'wmTssaDAAAXPKmDdMagqOqs7YBV8hXzQ'})
                this.$api.qywx_external_contact_contact_base64({external_userid:this.external_userid})
                .then(res=>{
                    this.showUser = true
                    this.loading = false
                    this.base64_avatar = res.data.base64_avatar
                    this.base64_qrcode = res.data.base64_qrcode
                })
            },
            getMsg() {
                // this.$api.qywx_external_contact_contact_way({external_userid:'wmTssaDAAAXPKmDdMagqOqs7YBV8hXzQ'})
                this.$api.qywx_external_contact_contact_way({external_userid:this.external_userid})
                .then(res=>{
                    this.user_info = res.data.contact_way
                    this.tagList = res.data.contact_way.auto_mark_tag
                    this.state = res.data.contact_way.state
                })
            },
            getInvited(){
                this.searchLoading = true
                // this.$api.qywx_external_contact_invited({external_userid:'wmTssaDAAAXPKmDdMagqOqs7YBV8hXzQ',type:this.btnNum,'per-page': this.pageSize, page: this.pageCurrent})
                this.$api.qywx_external_contact_invited({external_userid:this.external_userid,type:this.btnNum,'per-page': this.pageSize, page: this.pageCurrent})
                .then(res=>{
                    this.total = res._data
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            showInvited(){
                this.getInvited()
                this.showInvite = true
            },
            onLoad(){
                if(this.pageCurrent !=1){
                    this.getInvited()
                }
            },
            onClose(){
                this.show = false
            },
            async print() {
                let that = this
                const el = this.$refs.printMe
                const options = {
                    type: 'dataURL'
                }
                this.output = await this.$html2canvas(el, options)
                let file = await this.base64toFile(this.output)
                upload({ file: file})
                .then(res => {
                    that.getMediaId(res.path)
                })
            },
            getMediaId(url){
                this.$api.qywx_jssdk_upload_media({file_type:'image',file_url:url})
                .then(res=>{
                    let obj = {
                        msgtype:'image',
                        image: {
                            mediaid:res.data.media_id,
                        },
                    }
                    wxWorkSend(obj).then(res=>{
                        Toast.success('发送成功')
                    }).catch(err=>{
                        if(err.err_msg == 'sendChatMessage:cancel') return false
                        Toast.fail('发送失败')
                    })
                })
            },
            base64toFile (dataurl, filename = 'file') {
                let arr = dataurl.split(',')
                let mime = arr[0].match(/:(.*?);/)[1]
                let suffix = mime.split('/')[1]
                let bstr = atob(arr[1])
                let n = bstr.length
                let u8arr = new Uint8Array(n)
                while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
                }
                return new File([u8arr], `${filename}.${suffix}`, {
                type: mime
                })
            },
            getCurExternalChat(){
                let that = this
                wx.invoke('getCurExternalContact', {
                    }, function(res){
                    if(res.err_msg == "getCurExternalContact:ok"){
                        that.external_userid  = res.userId //返回当前外部联系人userId
                        that.getBase64()
                        that.getMsg()
                    }else {
                        //错误处理
                    }
                })
            },
            toSearch(num){
                this.list = []
                this.pageCurrent = 1
                this.finished = false
                this.btnNum = num
                this.getInvited()
            }
        },
        watch: {
            qywx_profile(newVal){
                this.getCurExternalChat()
            }
        },
    }
</script>

<style lang="less" scoped>
    .personCard{
        min-height: 100vh;
        background: #fff;
        &-bg{
            background: #f8f8f8;
            height: 8px;
        }
        &-nav{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
            .van-button--mini{
                font-size: 14px;
                margin-right: 10px;
            }
            &-tag{
                margin: 5px 5px 5px 0;
            }
        }
        &-title{
            display: flex;
            align-items: center;
            position: relative;
            padding: 10px 0;
            h1{
                font-size: 20px;
                margin-left: 10px;
            }
            &-Refresh{
                display: flex;
                align-items: center;
                position: absolute;
                right: 10px;
                color: #00cca2;
                span{
                    margin-left: 5px;
                }
            }

        }
        &-printMe{
            width: 90%;
            background: #fff;
            margin: 0 auto;
            border-radius: 2px;
            box-shadow: 0 0 1px #f2f2f2;
            text-align: center;
            padding-bottom: 25px;
            border: 1px solid rgba(0, 0, 0, 0.07);
            &-user{
                display: flex;
                align-items: center;
                padding: 25px 0 0 25px;
                img{
                    width: 56px;
                    height: 56px;
                    border-radius: 2px;
                    margin-right: 15px; 
                }
                h1{
                    font-size: 22px;
                    margin-bottom: 5px;
                }
            }
            &-img{
                width: 260px;
                height: 260px;
                margin: 15px 0;
            }
        }
        &-btn{
            padding: 0 20px;
            margin: 15px 0;
        }
        &-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        &-block{
            width: 85%;
            height: 350px;
            padding: 10px;
            background: #fff;
            border-radius: 4px;
            h1{
                font-size: 16px;
                text-align: center;
                padding: 5px 0;
            }
        }
        &-content{
            padding: 0 15px;
            &-num{
                display: flex;
                align-items: center;
                margin: 10px 0;
                span{
                    font-size: 12px;
                }
            }
            &-btn{
                margin-top: 10px;
                .van-button{
                    margin-right: 10px;
                }
            }
            &-item{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                    margin-right: 10px;
                }
            }
            &-list{
                height: 300px;
                overflow-y: scroll;
            }
        }
    }
</style>